
//
//
// aos.js
//
// Initialises the animate on scroll plugin

import AOS from 'aos';

$(document).ready(() => {
  AOS.init({ once: true });
});
