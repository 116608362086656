import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["code"]

  copyCode() {
    const code = this.codeTarget.textContent
    navigator.clipboard.writeText(code).then(() => {
      // Optional: Add visual feedback that copy succeeded
      const originalText = this.element.querySelector('button').textContent
      this.element.querySelector('button').textContent = 'Copied!'
      setTimeout(() => {
        this.element.querySelector('button').textContent = originalText
      }, 2000)
    }).catch(err => {
      console.error('Failed to copy code:', err)
    })
  }
}
    