import { Controller } from "stimulus"

export default class extends Controller {
  upgrade(e) {
    const planName = event.target.getAttribute("data-plan-name")
    fetch(`/dashboard/upgrade?plan=${planName}`)
      .then(response => response.text())
      .then(response => {
        try {
          const data = JSON.parse(response)
          const stripe = Stripe(data["stripe_key"])

          stripe.redirectToCheckout({
            sessionId: data["session_id"]
          }).then(function (result) {
            alert(result.error.message)
          })
        } catch (error) {
          alert("Upgrade error, please contact support.")
        }
      })

    e.preventDefault()
  }
}
