//
//
// prism.js
//
// Initialises the prism code highlighting plugin

import Prism from 'prismjs';
import 'prismjs/components/prism-python'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-bash'
import 'prismjs/components/prism-markup-templating' // require for php https://github.com/PrismJS/prism/issues/2769
import 'prismjs/components/prism-php'
import 'prismjs/components/prism-c'
import 'prismjs/components/prism-csharp'
import 'prismjs/components/prism-java'
import 'prismjs/components/prism-ruby'
import 'prismjs/components/prism-swift'
import 'prismjs/components/prism-go'
import 'prismjs/components/prism-rust'

Prism.highlightAll();
