import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const hash = document.location.hash
    const prefix = "tab_"
    if (hash) {
      $('.nav-tabs a[href="'+hash.replace(prefix,"")+'"]').tab('show')
    }

    $('.nav-tabs a').on('click', function (e) {
      let id = $(e.target).attr("href").substr(1)
      window.location.hash = id
    })
  }

  upgrade() {
    let $billingTab = $('#billing-tab')
    $billingTab.tab('show')
    $billingTab[0].scrollIntoView()
  }
}
