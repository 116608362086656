import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "apiKey", "url", "js", "jsOptions", "jsTimeout", 
    "waitFor", "proxy", "country", "question", "preview", "response",
    "responseArea", "language", "codePreview", "tryButton", "spinner",
    "responseFormat", "rawResponse", "htmlResponse", "responseFrame",
    "headers", "timeout", "jsScript", "returnScriptResult", "customProxy",
    "credits", "device", "errorOn404", "errorOnRedirect",
    "format", "endpoint", "aiOptions",
    "textOptions", "textFormat", "returnLinks"
  ]

  static values = {
    currentUserApiKey: String,
    headerRowTemplate: String
  }

  connect() {
    // Save the first header row template
    const firstRow = this.headersTarget.querySelector('.header-row')
    if (firstRow) {
      this.headerRowTemplateValue = firstRow.outerHTML
    }

    this.loadStateFromStorage()
    this.updateRequest()
    this.toggleAiOptions()
    this.toggleTextOptions()
  }

  loadStateFromStorage() {
    // Load values from localStorage
    this.apiKeyTarget.value = localStorage.getItem('apiKey') || this.currentUserApiKeyValue || ''
    this.urlTarget.value = localStorage.getItem('url') || 'https://example.com'
    this.jsTarget.checked = localStorage.getItem('js') === null ? true : localStorage.getItem('js') === 'true'
    this.jsTimeoutTarget.value = localStorage.getItem('jsTimeout') || '2000'
    this.waitForTarget.value = localStorage.getItem('waitFor') || ''
    
    const savedProxy = localStorage.getItem('proxy') || 'datacenter'
    this.proxyTargets.find(el => el.value === savedProxy).checked = true
    
    this.countryTarget.value = localStorage.getItem('country') || 'us'
    this.questionTarget.value = localStorage.getItem('question') || ''
    this.timeoutTarget.value = localStorage.getItem('timeout') || '10000'
    this.jsScriptTarget.value = localStorage.getItem('jsScript') || ''
    this.returnScriptResultTarget.checked = localStorage.getItem('returnScriptResult') === 'true'
    this.customProxyTarget.value = localStorage.getItem('customProxy') || ''
    this.deviceTarget.value = localStorage.getItem('device') || 'desktop'
    this.errorOn404Target.checked = localStorage.getItem('errorOn404') === 'true'
    this.errorOnRedirectTarget.checked = localStorage.getItem('errorOnRedirect') === 'true'
    this.formatTarget.value = localStorage.getItem('format') || 'text'
    this.textFormatTarget.value = localStorage.getItem('textFormat') || 'plain'
    this.returnLinksTarget.checked = localStorage.getItem('returnLinks') === 'true'

    // Load endpoint
    const savedEndpoint = localStorage.getItem('endpoint') || 'html'
    this.endpointTargets.find(el => el.value === savedEndpoint).checked = true

    // Load headers
    const savedHeaders = JSON.parse(localStorage.getItem('headers') || '[]')
    // Clear existing headers
    this.headersTarget.innerHTML = ''
    if (savedHeaders.length > 0) {
      // Add saved headers
      savedHeaders.forEach(header => {
        this.addHeaderRow(header.key, header.value)
      })
    } else {
      // Add one empty header row if no saved headers
      this.addHeaderRow('', '')
    }

    // Load selected language
    const savedLanguage = localStorage.getItem('language')
    if (savedLanguage) {
      this.languageTargets.find(el => el.value === savedLanguage).checked = true
    }

    // Load response format
    const savedFormat = localStorage.getItem('responseFormat')
    if (savedFormat) {
      this.responseFormatTargets.find(el => el.value === savedFormat).checked = true
    }
  }

  saveStateToStorage() {
    localStorage.setItem('apiKey', this.apiKeyTarget.value)
    localStorage.setItem('url', this.urlTarget.value)
    localStorage.setItem('js', this.jsTarget.checked)
    localStorage.setItem('jsTimeout', this.jsTimeoutTarget.value)
    localStorage.setItem('waitFor', this.waitForTarget.value)
    
    // Save selected proxy radio value
    const selectedProxy = this.proxyTargets.find(el => el.checked)
    if (selectedProxy) {
      localStorage.setItem('proxy', selectedProxy.value)
    }
    
    localStorage.setItem('country', this.countryTarget.value)
    localStorage.setItem('question', this.questionTarget.value)
    localStorage.setItem('timeout', this.timeoutTarget.value)
    localStorage.setItem('jsScript', this.jsScriptTarget.value)
    localStorage.setItem('returnScriptResult', this.returnScriptResultTarget.checked)
    localStorage.setItem('customProxy', this.customProxyTarget.value)
    localStorage.setItem('device', this.deviceTarget.value)
    localStorage.setItem('errorOn404', this.errorOn404Target.checked)
    localStorage.setItem('errorOnRedirect', this.errorOnRedirectTarget.checked)
    localStorage.setItem('format', this.formatTarget.value)
    localStorage.setItem('textFormat', this.textFormatTarget.value)
    localStorage.setItem('returnLinks', this.returnLinksTarget.checked)

    // Save selected endpoint
    const selectedEndpoint = this.endpointTargets.find(el => el.checked)
    if (selectedEndpoint) {
      localStorage.setItem('endpoint', selectedEndpoint.value)
    }

    // Save headers
    const headers = []
    this.headersTarget.querySelectorAll('.header-row').forEach(row => {
      const inputs = row.querySelectorAll('input')
      if (inputs[0].value && inputs[1].value) {
        headers.push({
          key: inputs[0].value,
          value: inputs[1].value
        })
      }
    })
    localStorage.setItem('headers', JSON.stringify(headers))

    // Save selected language
    const selectedLanguage = this.languageTargets.find(el => el.checked)
    if (selectedLanguage) {
      localStorage.setItem('language', selectedLanguage.value)
    }

    // Save response format
    const selectedFormat = this.responseFormatTargets.find(el => el.checked)
    if (selectedFormat) {
      localStorage.setItem('responseFormat', selectedFormat.value)
    }
  }

  resetForm() {
    // Only clear keys used by this controller
    localStorage.removeItem('apiKey')
    localStorage.removeItem('url')
    localStorage.removeItem('js')
    localStorage.removeItem('jsTimeout')
    localStorage.removeItem('waitFor')
    localStorage.removeItem('proxy')
    localStorage.removeItem('country')
    localStorage.removeItem('question')
    localStorage.removeItem('timeout')
    localStorage.removeItem('jsScript')
    localStorage.removeItem('returnScriptResult')
    localStorage.removeItem('customProxy')
    localStorage.removeItem('headers')
    localStorage.removeItem('language')
    localStorage.removeItem('responseFormat')
    localStorage.removeItem('device')
    localStorage.removeItem('errorOn404')
    localStorage.removeItem('errorOnRedirect')
    localStorage.removeItem('format')
    localStorage.removeItem('endpoint')
    localStorage.removeItem('textFormat')
    localStorage.removeItem('returnLinks')
    
    this.loadStateFromStorage()
    
    // Reset headers
    this.headersTarget.innerHTML = ''
    this.addHeaderRow('', '')
    
    this.updateRequest()
  }

  updateRequest() {
    const selectedEndpoint = this.endpointTargets.find(el => el.checked)
    let endpoint = selectedEndpoint ? selectedEndpoint.value : 'html'
    let params = this.buildParams()
    const queryString = new URLSearchParams(params).toString()
    this.resultUrl = `https://api.webscraping.ai/${endpoint}?${queryString}`
    
    this.updateCodePreview(endpoint, params)
    this.updateCredits()
    this.saveStateToStorage()
  }

  updateCredits() {
    let credits = 5 // Default: js=true + proxy=datacenter
    
    const selectedEndpoint = this.endpointTargets.find(el => el.checked)
    if (selectedEndpoint && selectedEndpoint.value === 'ai/question') {
      credits = 50
    } else {
      // Regular request pricing
      const isJs = this.jsTarget.checked
      const isResidential = this.proxyTargets.find(el => el.checked).value === 'residential'

      if (!isJs && !isResidential) credits = 1
      else if (!isJs && isResidential) credits = 10
      else if (isJs && !isResidential) credits = 5
      else if (isJs && isResidential) credits = 25
    }

    this.creditsTarget.textContent = credits
  }

  toggleAiOptions() {
    const selectedEndpoint = this.endpointTargets.find(el => el.checked)
    const isAiEndpoint = selectedEndpoint && selectedEndpoint.value === 'ai/question'
    this.aiOptionsTargets.forEach(target => {
      target.classList.toggle('hidden', !isAiEndpoint)
    })
    this.updateRequest()
  }

  toggleTextOptions() {
    const selectedEndpoint = this.endpointTargets.find(el => el.checked)
    const isTextEndpoint = selectedEndpoint && selectedEndpoint.value === 'text'
    this.textOptionsTargets.forEach(target => {
      target.classList.toggle('hidden', !isTextEndpoint)
    })
    this.updateRequest()
  }

  buildParams() {
    const params = {
      api_key: this.apiKeyTarget.value,
      url: this.urlTarget.value,
      timeout: this.timeoutTarget.value
    }

    // Build headers from input fields
    const headers = {}
    const headerRows = this.headersTarget.querySelectorAll('.header-row')
    headerRows.forEach(row => {
      const key = row.querySelector('input[placeholder="Key"]').value
      const value = row.querySelector('input[placeholder="Value"]').value
      if (key && value) {
        headers[key] = value
      }
    })
    if (Object.keys(headers).length > 0) {
      params.headers = JSON.stringify(headers)
    }

    if (this.jsTarget.checked) {
      params.js = "true"
      params.js_timeout = this.jsTimeoutTarget.value
      
      if (this.waitForTarget.value) {
        params.wait_for = this.waitForTarget.value
      }

      if (this.jsScriptTarget.value) {
        params.js_script = this.jsScriptTarget.value
      }

      if (this.returnScriptResultTarget.checked) {
        params.return_script_result = "true"
      }
    }

    if (this.customProxyTarget.value) {
      params.customProxy = this.customProxyTarget.value
    } else {
      const selectedProxy = this.proxyTargets.find(el => el.checked)
      if (selectedProxy && selectedProxy.value !== "datacenter") {
        params.proxy = selectedProxy.value
      }
    }

    if (this.countryTarget.value !== "us") {
      params.country = this.countryTarget.value
    }

    const selectedEndpoint = this.endpointTargets.find(el => el.checked)
    if (selectedEndpoint && selectedEndpoint.value === 'ai/question') {
      params.question = this.questionTarget.value
    }

    if (selectedEndpoint && selectedEndpoint.value === 'text') {
      if (this.textFormatTarget.value !== "plain") {
        params.text_format = this.textFormatTarget.value
      }
      if (this.returnLinksTarget.checked) {
        params.return_links = "true"
      }
    }

    if (this.deviceTarget.value !== "desktop") {
      params.device = this.deviceTarget.value
    }

    if (this.errorOn404Target.checked) {
      params.error_on_404 = "true"
    }

    if (this.errorOnRedirectTarget.checked) {
      params.error_on_redirect = "true"
    }

    if (this.formatTarget.value !== "text") {
      params.format = this.formatTarget.value
    }

    return params
  }

  addHeaderRow(key = '', value = '') {
    const template = document.createElement('div')
    template.innerHTML = this.headerRowTemplateValue
    const newRow = template.firstElementChild
    
    const inputs = newRow.querySelectorAll('input')
    inputs[0].value = key
    inputs[1].value = value
    
    this.headersTarget.appendChild(newRow)
  }

  addHeader() {
    this.addHeaderRow()
    this.updateRequest()
  }

  removeHeader(event) {
    const headerRows = this.headersTarget.querySelectorAll('.header-row')
    if (headerRows.length > 1) {
      event.target.closest('.header-row').remove()
      this.updateRequest()
    } else {
      // Clear inputs if it's the last row
      event.target.closest('.header-row').querySelectorAll('input').forEach(input => input.value = '')
      this.updateRequest()
    }
  }

  updateCodePreview(endpoint, params) {
    const selectedLanguage = this.languageTargets.find(el => el.checked).value
    const baseUrl = 'https://api.webscraping.ai/' + endpoint
    let code = ''

    switch(selectedLanguage) {
      case 'curl':
        code = `curl "${baseUrl}?${new URLSearchParams(params)}"`
        break
      case 'nodejs':
        code = `const https = require('https');

const params = ${JSON.stringify(params, null, 2)};
const queryString = new URLSearchParams(params).toString();
const url = '${baseUrl}?' + queryString;

https.get(url, (resp) => {
  let data = '';
  resp.on('data', (chunk) => { data += chunk; });
  resp.on('end', () => {
    console.log(data);
  });
}).on("error", (err) => {
  console.log("Error: " + err.message);
});`
        break
      case 'python':
        code = `import requests

params = ${JSON.stringify(params, null, 2)}
response = requests.get('${baseUrl}', params=params)
print(response.text)`
        break
      case 'ruby':
        code = `require 'net/http'
require 'uri'

params = ${JSON.stringify(params, null, 2)}
uri = URI('${baseUrl}')
uri.query = URI.encode_www_form(params)

response = Net::HTTP.get_response(uri)
puts response.body`
        break
      case 'php':
        code = `<?php
$params = ${JSON.stringify(params, null, 2)};
$query = http_build_query($params);
$url = '${baseUrl}?' . $query;

$response = file_get_contents($url);
echo $response;
?>`
        break
    }

    // Update code preview with language class
    this.codePreviewTarget.textContent = code
    
    // Map language names to Prism's language identifiers
    const languageMap = {
      'nodejs': 'javascript',
      'python': 'python',
      'ruby': 'ruby', 
      'php': 'php',
      'curl': 'bash'
    }
    const prismLanguage = languageMap[selectedLanguage] || selectedLanguage
    this.codePreviewTarget.className = `language-${prismLanguage}`
    
    // Trigger syntax highlighting if Prism is available
    if (typeof Prism !== 'undefined') {
      Prism.highlightElement(this.codePreviewTarget)
    }
  }

  async executeRequest() {
    try {
      // Show spinner and disable button
      this.spinnerTarget.classList.remove("hidden")
      this.tryButtonTarget.disabled = true

      const response = await fetch(this.resultUrl)
      const data = await response.text()
      
      this.responseTarget.textContent = data
      this.responseAreaTarget.style.display = "block"

      // Handle HTML preview if selected
      const selectedFormat = this.responseFormatTargets.find(el => el.checked).value
      if (selectedFormat === 'html') {
        this.rawResponseTarget.classList.add('hidden')
        this.htmlResponseTarget.classList.remove('hidden')
        this.responseFrameTarget.srcdoc = data
      } else {
        this.rawResponseTarget.classList.remove('hidden')
        this.htmlResponseTarget.classList.add('hidden')
      }

    } catch (error) {
      this.responseTarget.textContent = `Error: ${error.message}`
      this.responseAreaTarget.style.display = "block"
      this.rawResponseTarget.classList.remove('hidden')
      this.htmlResponseTarget.classList.add('hidden')
    } finally {
      // Hide spinner and enable button
      this.spinnerTarget.classList.add("hidden") 
      this.tryButtonTarget.disabled = false
    }
  }

  toggleResponseFormat() {
    const selectedFormat = this.responseFormatTargets.find(el => el.checked).value
    if (selectedFormat === 'html') {
      this.rawResponseTarget.classList.add('hidden')
      this.htmlResponseTarget.classList.remove('hidden')
      if (this.responseTarget.textContent) {
        this.responseFrameTarget.srcdoc = this.responseTarget.textContent
      }
    } else {
      this.rawResponseTarget.classList.remove('hidden')
      this.htmlResponseTarget.classList.add('hidden')
    }
    this.saveStateToStorage()
  }

  copyCode() {
    const code = this.codePreviewTarget.textContent
    navigator.clipboard.writeText(code)
  }
}
